export const menulist = [
  {
    keyIndex: 1,
    link: "/",
    routerName: "home",
    name: "Home",
    icon: "home",
  },
  {
    keyIndex: 7,
    subMenuIcon: "chevron-down",
    subMenuTitle: "Account Tables",
    subMenuList: [
      {
        keyIndex: 8,
        link: "/2-step-table",
        routerName: "2-step-table",
        name: "2 - Step",
        icon: "table",
      },
      {
        keyIndex: 9,
        link: "/1-step-table",
        routerName: "1-step-table",
        name: "1 - Step",
        icon: "table",
      },
      {
        keyIndex: 10,
        link: "/direct-table",
        routerName: "direct-table",
        name: "Direct",
        icon: "table",
      },
    ],
  },
  {
    keyIndex: 2,
    subMenuIcon: "chevron-down",
    subMenuTitle: "Funding Plans",
    subMenuList: [
      {
        keyIndex: 3,
        link: "/2-step",
        routerName: "2-step",
        name: "2 - Step",
        icon: "grid",
      },
      {
        keyIndex: 4,
        link: "/1-step",
        routerName: "1-step",
        name: "1 - Step",
        icon: "grid",
      },
      {
        keyIndex: 5,
        link: "/direct",
        routerName: "direct",
        name: "Direct",
        icon: "grid",
      },
      {
        keyIndex: 6,
        link: "/scaling",
        routerName: "scaling",
        name: "Scaling",
        icon: "grid",
      },
    ],
  },
  {
    keyIndex: 11,
    subMenuIcon: "chevron-down",
    subMenuTitle: "Free Resources",
    subMenuList: [
      {
        keyIndex: 12,
        link: "/leaderboard",
        routerName: "leaderboard",
        name: "Leaderboard",
        icon: "users",
      },
    ],
  },
  {
    keyIndex: 13,
    link: "/affiliate",
    routerName: "affiliate",
    name: "Affiliate",
    icon: "grid",
  },
  {
    keyIndex: 14,
    link: "/footer",
    routerName: "footer",
    name: "Footer",
    icon: "sidebar",
  },
  {
    keyIndex: 15,
    subMenuIcon: "chevron-down",
    subMenuTitle: "Blogs",
    subMenuList: [
      {
        keyIndex: 16,
        link: "/blog",
        routerName: "blog",
        name: "Blog",
        icon: "book",
      },
      {
        keyIndex: 17,
        link: "/blog/category",
        routerName: "blog-category",
        name: "Category",
        icon: "list",
      },
      {
        keyIndex: 18,
        link: "/blog/tag",
        routerName: "blog-tag",
        name: "Tag",
        icon: "tag",
      },
    ],
  },
];