import { createRouter, createWebHistory } from "vue-router";

let routes = [
  // Login Route
  {
    path: "/",
    redirect: "/home",
  },
  // Login Route
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      authRequired: false,
    },
  },
  // home page
  { 
    path: "/home", 
    name: "home", 
    component: () => import("../views/home/index.vue"), 
    meta: {
      authRequired: true,
    },
  }, 
   // leader board
   {
    path: "/leaderboard",
    name: "leaderboard",
    component: () => import("@/views/leaderboard/index.vue"),
    meta: {
      authRequired: true,
    },
  }, 

  // 1-step
  {
    path: "/1-step",
    name: "1-step",
    component: () => import("@/views/1-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 2-step
  {
    path: "/2-step",
    name: "2-step",
    component: () => import("@/views/2-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // direct
  {
    path: "/direct",
    name: "direct",
    component: () => import("@/views/direct.vue"),
    meta: {
      authRequired: true,
    },
  },
 
  // scaling
  {
    path: "/scaling",
    name: "scaling",
    component: () => import("@/views/scaling.vue"),
    meta: {
      authRequired: true,
    },
  },

  // affiliate
  {
    path: "/affiliate",
    name: "affiliate",
    component: () => import("@/views/affiliate.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/blog/index.vue"),
    meta: {
      authRequired: true,
    },
  },
 
  // blog add
  {
    path: "/blog/add",
    name: "blog-add",
    component: () => import("@/views/blog/blog.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog edit
  {
    path: "/blog/edit/:id",
    name: "blog-edit",
    component: () => import("@/views/blog/blog.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog category
  {
    path: "/blog/category",
    name: "blog-category",
    component: () => import("@/views/blog/category.vue"),
    meta: {
      authRequired: true,
    },
  },

  // blog tag
  {
    path: "/blog/tag",
    name: "blog-tag",
    component: () => import("@/views/blog/tag.vue"),
    meta: {
      authRequired: true,
    },
  },

  // footer
  {
    path: "/footer",
    name: "footer",
    component: () => import("@/views/footer.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 1-step table
  {
    path: "/1-step-table",
    name: "1-step-table",
    component: () => import("@/views/tables/1-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // 2-step table
  {
    path: "/2-step-table",
    name: "2-step-table",
    component: () => import("@/views/tables/2-step.vue"),
    meta: {
      authRequired: true,
    },
  },

  // direct table
  {
    path: "/direct-table",
    name: "direct-table",
    component: () => import("@/views/tables/direct.vue"),
    meta: {
      authRequired: true,
    },
  },

  // PAGE NOT FOUND
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "",
  linkExactActiveClass: "",
});
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  const isLogin = localStorage.getItem("user");

  if (authRequired && !isLogin) {
    next("/login");
  } else if (!authRequired && isLogin) {
    next("/home");
  } else {
    next();
  }
});


export default router;
